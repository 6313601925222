var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{staticStyle:{"margin-bottom":"20px"}},[_c('el-col',{attrs:{"span":17}},[_c('el-card',{staticStyle:{"margin-bottom":"10px"}},[_c('search-filter',{attrs:{"searchName":'搜索作业',"keywordOptions":{ placeholder: '搜索作业' },"searchOptions":_vm.whereOptions.filter(
              (d) =>
                ['diff_id', 'match_type', 'match_id'].indexOf(
                  d.options.prop
                ) > -1
            ),"otherOptions":_vm.whereOptions.filter(
              (d) =>
                ['diff_id', 'match_type', 'match_id'].indexOf(
                  d.options.prop
                ) === -1
            ),"total":_vm.total},on:{"search":function($event){return _vm.$refs.table.reset(...arguments)},"eleChange":(v) => {
              _vm.hotRecommendHeight = v ? 260 : 100;
            }},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}})],1),_c('div',{staticClass:"operate"},[_c('el-button-group',[_c('el-button',{attrs:{"disabled":!_vm.$isPowers('courseTaskCreate'),"type":"primary","size":"small"},on:{"click":_vm.handleCreate}},[_vm._v("创建作业")])],1)],1)],1),_c('el-col',{attrs:{"span":6,"offset":1}},[_c('hot-recommend',{attrs:{"height":_vm.hotRecommendHeight,"type":"questOther","where":{ type: 3 }}})],1)],1),_c('el-card',[_c('com-table',{ref:"table",attrs:{"data":_vm.tableData,"columns":_vm.columns,"auto-load":false,"total":_vm.total,"edit-method":_vm.handleEditChange},on:{"update:total":function($event){_vm.total=$event}},scopedSlots:_vm._u([{key:"action",fn:function(scope){return [_c('el-button-group',[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$handleRoute({ other_id: scope.row.id }, 'teachingTaskInfo')}}},[_vm._v("查看")]),_c('el-button',{attrs:{"type":"text","size":"small","disabled":!_vm.$isPowers('courseTaskEdit') ||
              scope.row.status == 1 ||
              scope.row.match_type == 2},on:{"click":function($event){return _vm.$handleRoute({ other_id: scope.row.id }, 'teachingTaskEdit')}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$handleRoute({ other_id: scope.row.id }, 'teachingTaskCensus')}}},[_vm._v("答题统计")]),_c('el-button',{attrs:{"type":"text","disabled":!_vm.$isPowers('courseTaskCensus') ||
              scope.row.status == 1 ||
              scope.row.match_type == 2,"size":"small"},on:{"click":function($event){return _vm.$handleRoute({ other_id: scope.row.id }, 'teachingTaskQuestion')}}},[_vm._v("题目管理")]),_c('el-button',{attrs:{"disabled":!_vm.$isPowers('courseTaskDel') ||
              scope.row.status == 1 ||
              scope.row.match_type == 2,"type":"text","size":"small"},on:{"click":function($event){_vm.$handleConfirm(
                '你确定删除此条作业信息吗？',
                '/xapi/question.other/delete',
                { id: scope.row.id },
                () => {
                  _vm.$refs.table.reload();
                }
              )}}},[_vm._v("删除")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }