<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-col :span="17">
        <el-card style="margin-bottom: 10px">
          <search-filter
            :searchName="'搜索作业'"
            :keywordOptions="{ placeholder: '搜索作业' }"
            :searchOptions="
              whereOptions.filter(
                (d) =>
                  ['diff_id', 'match_type', 'match_id'].indexOf(
                    d.options.prop
                  ) > -1
              )
            "
            :otherOptions="
              whereOptions.filter(
                (d) =>
                  ['diff_id', 'match_type', 'match_id'].indexOf(
                    d.options.prop
                  ) === -1
              )
            "
            v-model="where"
            :total="total"
            @search="$refs.table.reset(...arguments)"
            @eleChange="
              (v) => {
                hotRecommendHeight = v ? 260 : 100;
              }
            "
          ></search-filter>
        </el-card>
        <div class="operate">
          <el-button-group>
            <!-- <el-button :disabled="!$isPowers('courseTaskCreate')" type="primary" size="small" @click="$handleRoute('teachingTaskCreate')">创建作业</el-button> -->
            <el-button
              :disabled="!$isPowers('courseTaskCreate')"
              type="primary"
              size="small"
              @click="handleCreate"
              >创建作业</el-button
            >
          </el-button-group>
        </div>
      </el-col>
      <el-col :span="6" :offset="1">
        <hot-recommend
          :height="hotRecommendHeight"
          type="questOther"
          :where="{ type: 3 }"
        ></hot-recommend>
      </el-col>
    </el-row>
    <el-card>
      <com-table
        ref="table"
        :data="tableData"
        :columns="columns"
        :auto-load="false"
        :total.sync="total"
        :edit-method="handleEditChange"
      >
        <template slot="action" slot-scope="scope">
          <el-button-group>
            <el-button
              type="text"
              size="small"
              @click="
                $handleRoute({ other_id: scope.row.id }, 'teachingTaskInfo')
              "
              >查看</el-button
            >
            <el-button
              type="text"
              size="small"
              :disabled="
                !$isPowers('courseTaskEdit') ||
                scope.row.status == 1 ||
                scope.row.match_type == 2
              "
              @click="
                $handleRoute({ other_id: scope.row.id }, 'teachingTaskEdit')
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="
                $handleRoute({ other_id: scope.row.id }, 'teachingTaskCensus')
              "
              >答题统计</el-button
            >
            <el-button
              type="text"
              :disabled="
                !$isPowers('courseTaskCensus') ||
                scope.row.status == 1 ||
                scope.row.match_type == 2
              "
              size="small"
              @click="
                $handleRoute({ other_id: scope.row.id }, 'teachingTaskQuestion')
              "
              >题目管理</el-button
            >
            <el-button
              :disabled="
                !$isPowers('courseTaskDel') ||
                scope.row.status == 1 ||
                scope.row.match_type == 2
              "
              @click="
                $handleConfirm(
                  '你确定删除此条作业信息吗？',
                  '/xapi/question.other/delete',
                  { id: scope.row.id },
                  () => {
                    $refs.table.reload();
                  }
                )
              "
              type="text"
              size="small"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  components: { HotRecommend, SearchFilter },
  props: ["priexRoute"],
  data() {
    var _this = this;
    return {
      hotRecommendHeight: 260,
      where: { is_plan: 0 },
      whereOptions: [
        {
          name: "el-form-item",
          options: {
            label: "难度",
            prop: "diff_id",
            type: "radio-group",
            data: { name: "question_other", keys: "diff_id" },
            defaultValue: "全部",
          },
        },
        {
          name: "el-form-item",
          options: {
            label: "类型",
            prop: "match_id",
            type: "radio-group",
            data: { name: "question_other", keys: "match" },
            defaultValue: "全部",
          },
        },
        {
          name: "el-form-item",
          options: {
            label: "作业类别",
            prop: "match_type",
            type: "radio-group",
            data: { name: "question_other", keys: "match_type_3" },
            defaultValue: "全部",
          },
        },
        {
          name: "el-form-item",
          options: { label: "年份", prop: "time_str", type: "year" },
        },
        {
          name: "el-form-item",
          options: {
            label: "地区",
            prop: "province_id",
            type: "select",
            data: { name: "question_other", keys: "province_id", isAll: false },
          },
        },
      ],
      columns: [
        {
          prop: "status",
          label: "状态",
          width: "120",
          align: "center",
          template: ({ row }) => {
            return (
              { "-2": "已结束", "-1": "未开始", 1: "正在进行" }[row.status] ||
              "-"
            );
          },
        },

        { prop: "title", label: "作业名称", minWidth: "220", align: "center" },
        {
          prop: "start_date",
          label: "开始时间",
          width: "160",
          align: "center",
        },
        { prop: "end_date", label: "结束时间", width: "160", align: "center" },
        {
          prop: "know_count",
          label: "知识点",
          width: "100",
          align: "center",
          template: ({ row }) => {
            return (row.know_count || (row.know_ids || []).length) + "个";
          },
        },
        { prop: "is_openStr", label: "权限", width: "100", align: "center" },
        {
          prop: "teach_name",
          label: "创建人",
          minWidth: "120",
          align: "center",
        },
        {
          prop: "create_date",
          label: "创建时间",
          width: "160",
          align: "center",
        },
        // {prop:'valid',label:'是否有效',width:'120' , slot:'switch' , align:'center' ,callback(props , {row}){props.disabled = row.status==1 || row.match_type==2 }},
        {
          prop: "is_publish",
          label: "是否布置",
          width: "120",
          slot: "switch",
          align: "center",
          callback(props, { row }) {
            props.disabled =
              (row.status == 1 || row.match_type == 2) && row.is_publish == 1;
          },
        },
        {
          fixed: "right",
          label: "操作",
          width: "220",
          slot: "action",
          align: "center",
        },
      ],
      total: 0,
      tableData: {
        url: "/xapi/question.other/myList/type/3",
      },
    };
  },
  methods: {
    handleCreate() {
      console.log('创建作业');
      localStorage.removeItem('isCourse')
      this.$handleRoute("teachingTaskCreate");
    },
    handleEditChange(v, ov, { row, column }) {
      this.$http
        .request({
          url: "/xapi/question.other/updateField",
          params: {
            field: column.prop,
            id: row.id,
            value: v,
          },
        })
        .catch(() => {
          row[column.prop] = ov;
        });
    },
  },
};
</script>

<style scoped>
</style>